import React from 'react';
import { ImageTitle } from '../../core/ImageTitle';
import { Tile } from '../../core/Tile';
import { GenericComponent, ToolbarComponent, InterfaceMode } from '../../dataForms/GenericComponent';
import { PagingModule } from '../../dataForms/GenericListComponent';

import { ToggleSwitchControl } from '../../controls/ToggleSwitchControl';
import { RelationshipItemsDisplayDataControl } from '../../dataControls/RelationshipItemsDisplayDataControl';
import { DataManager } from '../../dataControls/DataManager';
import { GenericDataAccess } from '../../dataControls/GenericDataAccess';
import { LabelDataControl } from '../../dataControls/LabelDataControl';
import { DropdownDataControl } from '../../dataControls/DropdownDataControl';
import { TextboxDataControl } from '../../dataControls/TextboxDataControl';
import { ChecklistDataControl } from '../../dataControls/ChecklistDataControls';
import { FileUpload } from '../../controls/FileUpload';
import { Card } from '../../components/Containers';


export class CertificationHomeView extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Certifications' />
                <div className='tile-container'>
                    <Tile title='Certification Types' onClick={() => window.Router.setRoute('/certifications/types')} />
                    <Tile title='Certificates' onClick={() => window.Router.setRoute('/certifications/certificates')} />

                </div>
            </div>
        );
    }
}


export class CertificationTypeView extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Certification Types' />
                <GenericComponent dataObject='certificationTypes' dataId={this.props.param_0} enableClone={false} />
            </div>
        );
    }
}

export class CertificateView extends React.Component {

    constructor(props) {
        super(props);

        this.dataAccess = new GenericDataAccess();

        var currentId = this.props.param_0 > 0 ? this.props.param_0 : null;
        this.state = {
            mode: currentId ? InterfaceMode.Display : InterfaceMode.List,
            currentId: currentId
        }
    }

    onListClick() {
        this.setState({
            mode: InterfaceMode.List,
            currentId: null
        })
    }

    onNewClick() {
        this.setState({
            mode: InterfaceMode.New,
            currentId: null
        })
    }

    onRowSelected(item) {
        console.log(item);
        this.setState({
            mode: InterfaceMode.Display,
            currentId: item.id
        })
    }

    onEditClick() {
        this.setState({
            mode: InterfaceMode.Edit,
        })
    }

    onDisplayClick() {
        this.setState({
            mode: InterfaceMode.Display,
        })
    }

    onDeleteClick() {
        var self = this;
        window.Confirmation.show('Delete', 'The item will be deleted. Do you want to continue?',
            () => {
                self.dataAccess.deleteItem('certificates', self.state.currentId, () => {
                    self.setState({
                        mode: InterfaceMode.List,
                        currentId: null
                    })
                });
            }
        );
    }

    onSaved(id) {
        this.setState({
            mode: InterfaceMode.List,
            currentId: id
        })
    }

    render() {
        return (
            <div className="view">
                <ImageTitle text='Certificates' />

                <ToolbarComponent mode={this.state.mode} enableClone={false} onNewClick={() => { this.onNewClick() }} onListClick={() => { this.onListClick() }} onEditClick={() => { this.onEditClick() }} onDisplayClick={() => { this.onDisplayClick() }} onDeleteClick={() => {this.onDeleteClick()} } />

                {this.state.mode === InterfaceMode.List &&
                    <CertificateListComponent id="list_module" dataObject='certificates' onRowSelected={item => { this.onRowSelected(item) }} />
                }

                {this.state.mode === InterfaceMode.New &&
                    <CertificateEditView onSaved={id => { this.onSaved(id) }} />
                }

                {this.state.mode === InterfaceMode.Edit &&
                    <CertificateEditView dataId={this.state.currentId} onSaved={id => { this.onSaved(id) }} />
                }

                {this.state.mode === InterfaceMode.Display &&
                    <CertificateDisplayView dataId={this.state.currentId} />
                }

            </div>
        )
    }
}

export class CertificateListComponent extends React.Component {
    constructor(props) {
        super(props);
        this.logger = window.BaseLogger;
        //this.dataAccess = window.GenericDataAccess;
        this.dataAccess = new GenericDataAccess();

        var listSettings = null;

        this.metaData = null;
        this.pageIndex = 0;
        this.pageSize = listSettings ? listSettings.pageSize : 200;
        this.totalPageCount = 0;

        this.state = {
            items: [],
            isFirst: true,
            isLast: true
        }

        this.onDeleting = this.onDeleting.bind(this);
        this.onRowSelected = this.onRowSelected.bind(this);
        this.onPageSizeChanged = this.onPageSizeChanged.bind(this);
        this.onFirstClick = this.onFirstClick.bind(this);
        this.onPreviousClick = this.onPreviousClick.bind(this);
        this.onNextClick = this.onNextClick.bind(this);
        this.onLastClick = this.onLastClick.bind(this);
        this.onPdfClick = this.onPdfClick.bind(this);
        this.onCsvClick = this.onCsvClick.bind(this);
        this.onGoogleClick = this.onGoogleClick.bind(this);
        this.loadList();
    }

    componentDidMount() {
        if (this.props.onInit) {
            this.props.onInit(this);
        }
    }

    onPageSizeChanged(pageSize) {
        this.pageSize = pageSize;
        this.pageIndex = 0;
        this.loadList();
    }

    onPdfClick() {
        var url = this.props.filter
            ? '/data/' + encodeURI(this.props.dataObject) + '?format=pdf&filter=' + encodeURI(this.props.filter)
            : '/data/' + encodeURI(this.props.dataObject) + '?format=pdf';

        window.location = url;
    }

    onCsvClick() {
        var url = this.props.filter
            ? '/data/' + encodeURI(this.props.dataObject) + '?format=csv&filter=' + encodeURI(this.props.filter)
            : '/data/' + encodeURI(this.props.dataObject) + '?format=csv';

        window.location = url;
    }

    onGoogleClick() {
        var url = this.props.filter
            ? `/export/${encodeURI(this.props.dataObject)}Export?filter=${encodeURI(this.props.filter)}`
            : `/export/${encodeURI(this.props.dataObject)}Export`;

        var options = "menubar=no,location=no,resizable=yes,scrollbars=yes,status=yes";
        window.open(url, 'ePac Data Export', options);
    }

    async refresh() {
        await this.loadList();
    }

    async loadList() {
        var result = await this.dataAccess.getMetaData(this.props.dataObject);
        this.metaData = result;
        this.loadItems();
    }

    loadItems() {
        var self = this;
        var filter = this.props.filter ? this.props.filter : null;

        this.dataAccess.getList(this.props.dataObject, this.pageSize, this.pageIndex, filter, function (result) {
            var page = result;
            self.totalPageCount = page.totalPageCount;

            self.setState({
                items: page.items,
                isFirst: page.isFirst,
                isLast: page.isLast
            });
        });
    }

    onFirstClick() {
        this.pageIndex = 0;
        this.loadList();
    }

    onPreviousClick() {
        if (this.pageIndex > 0) {
            this.pageIndex--;
        }
        this.loadList();
    }

    onNextClick() {
        if (this.pageIndex < this.totalPageCount - 1) {
            this.pageIndex++;
        }
        this.loadList();
    }

    onLastClick() {
        this.pageIndex = this.totalPageCount - 1;
        this.loadList();
    }

    onRowSelected(item) {
        if (this.props.onRowSelected) {
            this.props.onRowSelected(item, this.props.dataObject);
        }
    }

    onDeleting(item) {
        var self = this;
        window.Confirmation.show('Delete', 'The item will be deleted. Do you want to continue?',
            () => {
                self.dataAccess.deleteItem(self.props.dataObject, item.id, function () {
                    self.loadList();
                    if (self.props.onDeleted) {
                        self.props.onDeleted(item, self.props.dataObject);
                    }
                });
            }
        );
    }

    onPublishToggle(item, published) {
        console.log(published);
        var self = this;
        self.dataAccess.getItem(
            self.props.dataObject,
            item.id,
            data => {
                data.published = published;
                self.dataAccess.saveItem(
                    self.props.dataObject,
                    data,
                    data => {
                        if (published) {
                            window.Alerts.showSuccess('The certificate has been published!', 'Certificate');
                        }
                        else {
                            window.Alerts.showSuccess('The certificate has been unpublished!', 'Certificate');
                        }
                        self.loadList();
                    }
                )
            }
        )
    }

    render() {
        return (
            <div className='generic-list-component'>
                <PagingModule pageIndex={this.pageIndex} totalPageCount={this.totalPageCount} isFirst={this.state.isFirst} isLast={this.state.isLast} onFirstClick={this.onFirstClick} onPreviousClick={this.onPreviousClick} onNextClick={this.onNextClick} onLastClick={this.onLastClick} onPageSizeChanged={this.onPageSizeChanged} onPdfClick={this.onPdfClick} onCsvClick={this.onCsvClick} onGoogleClick={this.onGoogleClick} />

                {this.state.items && this.state.items.length > 0 &&
                    <div className='table'>
                        <div className="table-header">
                            <div className='row'>
                                <div className='col col-1' />
                                <div className='col col-1'>Id</div>
                                <div className='col col-3'>Name</div>
                                <div className='col col-2'>Type</div>
                                <div className='col col-2'>Date</div>
                                <div className='col col-2'>User</div>
                                <div className='col col-1'>Published</div>
                            </div>
                        </div>

                        <div className='body'>
                            {this.state.items.map((item, index1) => {
                                return (
                                    <div className="row selectable border-bottom" key={'row_' + index1}>
                                        <div className='col col-1'>
                                            <button className='btn btn-primary-outline' onClick={() => { this.onDeleting(item) }}><span className='glyphicon glyphicon-trash'></span></button>
                                        </div>

                                        <div className='col col-1' onClick={() => { this.onRowSelected(item)}}>{item['id']}</div>
                                        <div className='col col-3' onClick={() => { this.onRowSelected(item)}}>{item['name']}</div>
                                        <div className='col col-3' onClick={() => { this.onRowSelected(item)}}>{item['certificationTypeId']}</div>
                                        <div className='col col-2' onClick={() => { this.onRowSelected(item)}}>{item['createDate']}</div>
                                        <div className='col col-2' onClick={() => { this.onRowSelected(item)}}>{item['createUser']}</div>
                                        <div className='col col-1'><ToggleSwitchControl on={item['published'] === 'True'} onChange={val => { this.onPublishToggle(item, val)} } /></div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                }


                {(!this.state.items || this.state.items.length == 0) &&
                    <div className="alert alert-info">
                        No results
                    </div>
                }

            </div>
        );
    }
}


export class CertificateEditView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            certificate: null,
            files: [],
            isValid: true,
            gussetRequired: true
        };

        this.dims = {
            lengthFrom: null,
            lengthTo: null,
            widthFrom: null,
            widthTo: null,
            gussetFrom: null,
            gussetTo: null
        };

        this.fileUpload = null;
        this.onControlBound = this.onControlBound.bind(this);
        this.onControlUnbound = this.onControlUnbound.bind(this);
        this.onValidated = this.onValidated.bind(this);
        this.dataManager = new DataManager('certificates', this.onControlBound, this.onControlUnbound, this.onValidated);
    }

    componentDidMount() {
        var self = this;
        //window.Loader.show();
        this.dataManager.init()
            .then(dataManager => {
                if (this.props.dataId) {
                    dataManager.loadContext(this.props.dataId)
                        .then(data => {
                            //2) bind Controls

                            var docs = [];
                            if (data.documents) {
                                data.documents.forEach(doc => {
                                    docs.push({
                                        id: doc.dataObject2.id,
                                        fileName: doc.dataObject2.name
                                    });
                                });
                            }

                            self.setState({
                                certificate: data,
                                files: docs
                            });
                            dataManager.bindControls();
                            window.Loader.hide();
                        })
                        .catch(() => {
                            dataManager.bindControls();
                            window.Loader.hide();
                        });
                }
                else {
                    dataManager.bindControls();
                    window.Loader.hide();
                }
            });
    }

    onControlBound(control, propertyName) {
        var value = this.dataManager.dataObject[propertyName];
        if (value) {
            control.setValue(value);
        }
    }

    onConvertingMethodChanged(val, item) {
        this.setState({ gussetRequired: item.gussetRequired })
    }

    onValidated(isValid) {

        this.setState({
            isValid: isValid
        });
    }

    onControlUnbound(control, propertyName) {
    }

    onUploadComplete(id, data, reset) {
        var files = this.state.files;
        files.push(JSON.parse(data));

        this.setState({
            files: files
        });

        reset();
    }

    onUploadFailed() {

    }

    onFileRemoveClick(fileId) {
        var files = this.state.files;
        var idx = files.findIndex(e => e.id == fileId);

        files.splice(idx, 1);

        this.setState({
            files: files
        });
    }

    onSaveClick() {
        var self = this;

        self.dataManager.dataObject.documents = [];
        self.state.files.forEach(doc => {
            var document = { dataObject2Id: doc.id };
            self.dataManager.dataObject.documents.push(document);
        });

        self.dataManager.saveContext()
            .then(data => {
                console.log('Item', data)
                if (this.props.onSaved) {
                    this.props.onSaved(data.id);
                }
            });
    }

    isRangeValid(val1, val2) {
        if (val1 === null || val2 === null) {
            return true;
        }

        if (parseFloat(val1) < parseFloat(val2)) {
            return true;
        }
        return false;
    }

    render() {
        return (
            <div className="view">

                <Card title='General' subtitle='Please enter the general certification information:'>
                    <div className='row'>
                        <div className='col col-12'>
                            <label htmlFor='name'>Name</label>
                            <TextboxDataControl dataManager={this.dataManager} propertyName='name' />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col col-12'>
                            <label htmlFor='certificationTypeId'>Type</label>
                            <DropdownDataControl dataManager={this.dataManager} propertyName='certificationTypeId' />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col col-12'>
                            <label htmlFor='description'>Description</label>
                            <TextboxDataControl dataManager={this.dataManager} propertyName='description' multiline={true} />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col col-12'>
                            <label htmlFor='convertingMethodId'>Converting Method</label>
                            <DropdownDataControl dataManager={this.dataManager} propertyName='convertingMethodId' onChange={(val, item) => { this.onConvertingMethodChanged(val, item) }} />
                        </div>
                    </div>
                </Card>

                <Card title='Pouch Dimensions' subtitle='Please enter the Pouch Dimension Ranges:'>
                    <div className='row'>
                        <div className='col col-2'>
                            <label>Length</label>
                        </div>

                        <div className='col col-5'>
                            <label htmlFor='lengthFrom'>From</label>
                            <TextboxDataControl dataManager={this.dataManager} propertyName='lengthFrom' proceedOnError={true} onChange={val => { this.dims.lengthFrom = val }} onValidate={val => { return this.isRangeValid(val, this.dims.lengthTo) }} />
                        </div>

                        <div className='col col-5'>
                            <label htmlFor='lengthTo'>To</label>
                            <TextboxDataControl dataManager={this.dataManager} propertyName='lengthTo' proceedOnError={true} onChange={val => { this.dims.lengthTo = val }} onValidate={val => { return this.isRangeValid(this.dims.lengthFrom, val) }} />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col col-2'>
                            <label>Width</label>
                        </div>

                        <div className='col col-5'>
                            <label htmlFor='widthFrom'>From</label>
                            <TextboxDataControl dataManager={this.dataManager} propertyName='widthFrom' proceedOnError={true} onChange={val => { this.dims.widthFrom = val }} onValidate={val => { return this.isRangeValid(val, this.dims.widthTo) }} />
                        </div>

                        <div className='col col-5'>
                            <label htmlFor='widthTo'>To</label>
                            <TextboxDataControl dataManager={this.dataManager} propertyName='widthTo' proceedOnError={true} onChange={val => { this.dims.widthTo = val }} onValidate={val => { return this.isRangeValid(this.dims.widthFrom, val) }} />
                        </div>
                    </div>

                    {this.state.gussetRequired &&
                        <div className='row'>
                            <div className='col col-2'>
                                <label>Gusset</label>
                            </div>

                            <div className='col col-5'>
                                <label htmlFor='gussetFrom'>From</label>
                                <TextboxDataControl dataManager={this.dataManager} propertyName='gussetFrom' proceedOnError={true} onChange={val => { this.dims.gussetFrom = val }} onValidate={val => { return this.isRangeValid(val, this.dims.gussetTo) }} />
                            </div>

                            <div className='col col-5'>
                                <label htmlFor='gussetTo'>To</label>
                                <TextboxDataControl dataManager={this.dataManager} propertyName='gussetTo' proceedOnError={true} onChange={val => { this.dims.gussetTo = val }} onValidate={val => { return this.isRangeValid(this.dims.gussetFrom, val) }} />
                            </div>
                        </div>
                    }
                </Card>

                <Card title='Structure Specs' subtitle='Please select the structure specs to use for this certificate:'>
                    <div className='col col-12'>
                        <ChecklistDataControl className='certSpecsList' dataManager={this.dataManager} propertyName='structureSpecs' />
                    </div>
                </Card>

                <Card title='Documents' subtitle='Please provide the documents that should be shared with the customer for this certification:'>
                    <div className='row'>
                        <div className='col col-12'>
                            <div className='flexContainer'>
                                {this.state.files.length < 4 &&
                                    <FileUpload id='fileUpload' ref={this.fileUpload} defaultLabel='Upload' url='/api/documents/upload/pdf' existingFileName='' onUploadComplete={(id, data, reset) => { this.onUploadComplete(id, data, reset) }} onUploadFailed={() => { this.onUploadFailed() }} />
                                }
                                {this.state.files.map((item, idx) => {
                                    return (
                                        <FileTile key={`fileTile_${idx}`} fileName={item.fileName} fileId={item.id} onDeleteClick={fileId => { this.onFileRemoveClick(fileId) }} />
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </Card>

                <button className='btn btn-primary pull-right' disabled={!this.state.isValid || this.state.files.length < 1} onClick={() => { this.onSaveClick() }}>Save</button>

            </div>
        )
    }
}

export class CertificateDisplayView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            certificate: null,
            files: [],
            isValid: true,
            gussetRequired: true
        };


        this.onControlBound = this.onControlBound.bind(this);
        this.onControlUnbound = this.onControlUnbound.bind(this);
        this.onValidated = this.onValidated.bind(this);
        this.dataManager = new DataManager('certificates', this.onControlBound, this.onControlUnbound, this.onValidated);
    }

    componentDidMount() {
        var self = this;
        //window.Loader.show();
        this.dataManager.init()
            .then(dataManager => {
                if (this.props.dataId) {
                    dataManager.loadContext(this.props.dataId)
                        .then(data => {
                            //2) bind Controls

                            var docs = [];
                            if (data.documents) {
                                data.documents.forEach(doc => {
                                    docs.push({
                                        id: doc.dataObject2.id,
                                        fileName: doc.dataObject2.name
                                    });
                                });
                            }

                            self.setState({
                                certificate: data,
                                files: docs
                            });

                            dataManager.bindControls();
                            window.Loader.hide();
                        })
                        .catch(() => {
                            dataManager.bindControls();
                            window.Loader.hide();
                        });
                }
                else {
                    dataManager.bindControls();
                    window.Loader.hide();
                }
            });
    }

    onControlBound(control, propertyName) {
        var value = this.dataManager.dataObject[propertyName];
        if (value) {
            control.setValue(value);
        }
    }

    onConvertingMethodChanged(val, item) {
        this.setState({ gussetRequired: item.gussetRequired })
    }

    onValidated(isValid) {

        this.setState({
            isValid: isValid
        });
    }

    onControlUnbound(control, propertyName) {
    }

    render() {
        return (
            <div className="view">
                <Card title='General' subtitle='This is the general certification information:'>
                    <div className='row'>
                        <div className='col col-12'>
                            <label htmlFor='name'>Name</label>
                            <LabelDataControl dataManager={this.dataManager} propertyName='name' />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col col-12'>
                            <label htmlFor='certificationTypeId'>Type</label>
                            <RelationshipItemsDisplayDataControl dataManager={this.dataManager} propertyName='certificationTypeId' display={['name']} noItemsText='No Structure Specs defined yet.' />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col col-12'>
                            <label htmlFor='description'>Description</label>
                            <LabelDataControl dataManager={this.dataManager} propertyName='description' />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col col-12'>
                            <label htmlFor='convertingMethodId'>Converting Method</label>
                            <RelationshipItemsDisplayDataControl dataManager={this.dataManager} propertyName='convertingMethodId' onRender={(d, r) => { return <a href={`/configuration/packaging/attributes/converting/${d.id}`}><span>{d.name}</span></a> }} />
                        </div>
                    </div>
                </Card>

                <Card title='Pouch Dimensions' subtitle='These are the pouch dimensions supported for this certification:'>
                    <div className='row'>
                        <div className='col col-2'>
                            <label>Length</label>
                        </div>

                        <div className='col col-5'>
                            <label htmlFor='lengthFrom'>From</label>
                            <LabelDataControl dataManager={this.dataManager} propertyName='lengthFrom' onRender={data => { return <span>{data ? parseFloat(data).toFixed(4) : 0}</span> }} />
                        </div>

                        <div className='col col-5'>
                            <label htmlFor='lengthTo'>To</label>
                            <LabelDataControl dataManager={this.dataManager} propertyName='lengthTo' onRender={data => { return <span>{data ? parseFloat(data).toFixed(4) : 0}</span> }} />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col col-2'>
                            <label>Width</label>
                        </div>

                        <div className='col col-5'>
                            <label htmlFor='widthFrom'>From</label>
                            <LabelDataControl dataManager={this.dataManager} propertyName='widthFrom' onRender={data => { return <span>{data ? parseFloat(data).toFixed(4) : 0}</span> }} />
                        </div>

                        <div className='col col-5'>
                            <label htmlFor='widthTo'>To</label>
                            <LabelDataControl dataManager={this.dataManager} propertyName='widthTo' onRender={data => { return <span>{data ? parseFloat(data).toFixed(4) : 0}</span> }} />
                        </div>
                    </div>

                    {this.state.gussetRequired && this.state.certificate && this.state.certificate.gussetTo && this.state.certificate.gussetTo > 0 &&
                        <div className='row'>
                            <div className='col col-2'>
                                <label>Gusset</label>
                            </div>

                            <div className='col col-5'>
                                <label htmlFor='gussetFrom'>From</label>
                                <LabelDataControl dataManager={this.dataManager} propertyName='gussetFrom' onRender={data => { return <span>{data ? parseFloat(data).toFixed(4) : 0}</span> }} />
                            </div>

                            <div className='col col-5'>
                                <label htmlFor='gussetTo'>To</label>
                                <LabelDataControl dataManager={this.dataManager} propertyName='gussetTo' onRender={data => { return <span>{data ? parseFloat(data).toFixed(4) : 0}</span> }} />
                            </div>
                        </div>
                    }
                </Card>


                <Card title='Structure Specs' subtitle='These are the structure specs that are supported by this certification:'>
                    <div className='row'>
                        <div className='col col-12'>
                            <RelationshipItemsDisplayDataControl dataManager={this.dataManager} propertyName='structureSpecs' onRender={(d, r) => { return <div className='row-padding border-bottom'>{d.isPublished === true ? <span className='glyphicon glyphicon-play color-green' title='Published' /> : <span className='glyphicon glyphicon-stop color-orange' title='Unpublished' />} <a href={`/structurespecs/${d.id}`}><span>{d.name} (SPEC-{d.id})</span></a></div> }} />
                        </div>
                    </div>
                </Card>

                <Card title='Documents' subtitle='These documents are associated with this certification:'>
                    <div className='row'>
                        <div className='col col-12'>
                            <div className='flexContainer'>
                                {this.state.files.map((item, idx) => {
                                    return (
                                        <FileDownloadTile key={`fileTile_${idx}`} fileName={item.fileName} fileId={item.id} />
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </Card>

            </div>
        )
    }
}

export class FileTile extends React.Component {

    constructor(props) {
        super(props);
    }

    onDeleteClick() {
        if (this.props.onDeleteClick) {
            this.props.onDeleteClick(this.props.fileId);
        }
    }

    render() {
        return (
            <div className='fileTile buttonTile uploadTile uploadLink'>
                <div className="header"></div>
                <div className="content">
                    <p className='title'>{this.props.fileName}</p>
                    <img className='logo' src='/images/pdf_logo.png' />
                </div>
                <div className="footer">
                    <button className='btn btn-primary' onClick={() => { this.onDeleteClick()} }>Remove</button>
                </div>
            </div>
        )
    }
}

export class FileDownloadTile extends React.Component {

    constructor(props) {
        super(props);
    }

    onClick() {
        var url = `/api/documents/id/${this.props.fileId}`;
        const a = document.createElement('a');
        a.href = url;
        a.download = url.split('/').pop();
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }


    render() {
        return (
            <div className='fileTile buttonTile uploadTile uploadLink clickable' onClick={() => { this.onClick() }}>
                <div className="header">
                </div>
                <div className="content">
                    <p className='title'>{this.props.fileName}</p>
                    <img className='logo' src='/images/pdf_logo.png' />
                </div>
                <div className="footer">
                </div>
            </div>
        )
    }
}